



















































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserItemDetail } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetUserItemDetail } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';

import { readItemAddressUpdateError, readUserWhitelistAddresses } from '@/store/main/getters';
import { dispatchItemAddressUpdate, dispatchGetUserWhitelistAddresses } from '@/store/main/actions';
import { dispatchItemExtend } from '@/store/main/actions';
import { dispatchItemProposeWithdrawal } from '@/store/main/actions';

import PlanDetailChart from "../components/PlanDetail.vue";
// import PlanDetailChart2 from "../../components/PlanDetail2.vue";
Vue.component('PlanDetailChart', PlanDetailChart);

@Component
export default class Dashboard extends Vue {
  public itemId = 0;
  public valid1 = true;
  public valid2 = true;
  public valid3 = true;
  public dialogTerminate = false;
  public dialogRenew = false;
  public dialogChangeAddress = false;
  public dialogProposeWithdrawal = false;

  public code_promotion_display_text: string = '';

  public address_to_wallet: boolean = false;
  public to_address_whitelist: string = '';
  public address: string = '';
  public withdrawalBlockchainType: string = '';
  public withdrawalBlockchainTypes = ['TRC20', 'Polygon'];
  public public_key: string = '';

  get item() {
    const t = readUserItemDetail(this.$store);

    if (t) {
      // chnage code promotion display text according to used promotion code
      if (t.code_promotion == 'DAO+66') {
        this.code_promotion_display_text = "1000 USDT 內利息 +66%，持續一個月";
      } else {
        this.code_promotion_display_text = "";
      }
      return t;
    } else {
      return '';
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get whitelist_addresses() {
    const address_objects = readUserWhitelistAddresses(this.$store);
    if (address_objects) {
      const out: string[] = [];
      for (var i = 0; i < address_objects.length; i++) {
        out.push(address_objects[i].name +' (' + address_objects[i].address + ')');
      }

      return out;
    }
  }

  public changedValue(value) {
    if (this.to_address_whitelist) {
      this.address = this.to_address_whitelist.split(')')[0].split('(')[1];
    }
  }

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  public async mounted() {
    await dispatchGetUserItemDetail(this.$store, {itemId: this.itemId});
    await dispatchGetConstants(this.$store);
    await dispatchGetUserWhitelistAddresses(this.$store);

    // initialize change address form: to_wallet checkbox
    if (this.item) {
      if (this.item.address == 'to_wallet') {
        this.address_to_wallet = true;
      } else {
        this.address_to_wallet = false;
      }
    }

    // initialize change address form: withdrawal blockchain type
    if (this.item) {
      this.withdrawalBlockchainType = this.item.withdrawal_blockchain_type;
    }
    

    // change supported blockchain according to the item's token
    if (this.item) {
      if (this.item.tokenType == 'USDT') {
        this.withdrawalBlockchainTypes = ['TRC20', 'BEP20', 'Polygon'];
      } else if (this.item.tokenType == 'USDC') {
        this.withdrawalBlockchainTypes = ['BEP20', 'Polygon'];
      } else if (this.item.tokenType == 'BUSD') {
        this.withdrawalBlockchainTypes = ['BEP20'];
      } else if (this.item.tokenType == 'ETH') {
        this.withdrawalBlockchainTypes = ['BEP20', 'Polygon'];
      }
    }

  }

  public addressRules() {
    return [(v) => !!v || '請填寫地址',
            (v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
          
  }

  public get itemAddressUpdateError() {
    return readItemAddressUpdateError(this.$store);
  }

  public async submitAddressChange() {
    if ((this.$refs.form as any).validate()) {
      if (this.address_to_wallet == true) {
        dispatchItemAddressUpdate(this.$store, {itemId: this.itemId, withdrawal_blockchain_type: this.withdrawalBlockchainType, address: "to_wallet", otp_key: this.public_key});  
      } else {
        dispatchItemAddressUpdate(this.$store, {itemId: this.itemId, withdrawal_blockchain_type: this.withdrawalBlockchainType, address: this.address, otp_key: this.public_key});
      }
    }
  }

  public async submitItemExtend() {
    dispatchItemExtend(this.$store, {itemId: this.itemId, otp_key: this.public_key});
  }

  public async submitItemProposeWithdrawal() {
    dispatchItemProposeWithdrawal(this.$store, {itemId: this.itemId, otp_key: this.public_key});
  }

  // update version
  checkVersion () {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    let params = (new URL(document.location.href).searchParams);
    let iid = params.get("itemID");

    if (iid) {
      this.itemId = parseInt(iid);
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
